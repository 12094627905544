import React from "react"
import styled from "styled-components"
import BrochureBlueImage from "../../images/brochure-image-blue.png"
import TechSpecsImage from "../../images/tech-specs-icon.png"
import PartsManualImage from "../../images/parts-manual-icon.png"
import OperatorManualImage from "../../images/operators-manual-blue-icon.png"
import WarrantyImage from "../../images/warranty-icon-blue.png"
import UnderTheHoodImage from "../../images/Asset-16timberline-under-the-hood-h100.jpg"
import './machinesec.scss'

const MachineSection = props => {
  return (
    <div className="machines-container">
      <div className="top-section">
        <h3>{props.title}</h3>
        <img src={props.image} alt={props.alt} />
      </div>
      <div className="manuals-wrapper">
        {props.underTheHoodLink ? 
        <a href={props.underTheHoodLink} target="_blank">
              <img
                style={{ display: props.displayHood }}
                src={UnderTheHoodImage}
                alt="Under the hood"
              />
              <p style={{ display: props.displayHood }}>Under the Hood</p></a>
              
              : ""}
          
          <a href={props.brochureLink} target="_blank">
              <img src={BrochureBlueImage} alt="Brochure" />
              <p>Brochure</p>
          </a>
          <a href={props.techLink} target="_blank">
              <img src={TechSpecsImage} alt="Brochure" />
              <p>Tech Specs</p>
          </a>
          <a href={props.partsLink} target="_blank">
              <img src={PartsManualImage} alt="parts manual" />
              <p>Parts Manual</p>
          </a>
          <a href={props.opsLink} target="_blank">
              <img src={OperatorManualImage} alt="operator manual" />
              <p>Operator Manual</p>
          </a>
          <a href={props.warrantyLink} target="_blank">
              <img src={WarrantyImage} alt="warranty" />
              <p>Warranty</p>
          </a>
      </div>

      </div>
  )
}

export default MachineSection
