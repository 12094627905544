import React, { useState, useEffect } from "react"
import MainLayout from "../components/MainLayout"
import PageHeadings from "../components/PageHeadings"
import SEO from "../components/seo"
import styled from "styled-components"
import MachineSection from "../components/support/MachineSection"
import "./registration.scss"
//Machine Images
import FloorLargeImage from "../images/orbitz-20-full-view-w260.jpg"
import SImage from "../images/s-series-disk-white-bg.jpg"
import MImage from "../images/m-series-26disk-white-bg.jpg"
import LImage from "../images/l-series-disk-white-bg.jpg"
import XlImage from "../images/xl-series-disk-white-bg.jpg"
import SXStandardImage from "../images/xs-standard-20d-traction-w260.jpg"
import XslargeImage from "../images/xs-compact-17d-pa-1-small.jpg"
//End of Machine Images

//*----------------OLD IMAGES------------------*
// import SLargeImage from "../images/S-SERIES-20-D-front-WEB-260.jpg"
// import MLargeImage from "../images/timberline-m-20-D-FRONT-WEB-w260.jpg"
// import LLargeImage from "../images/TIMBERLINE-L-SERIES-32-D-front-WEB-w260.jpg"
// import XSSmallImageStandard from "../images/xs-standard-20D-TRACTIONx100.jpg"
// import XSImage from "../images/xs-compact-17d-pa-1-w100.png"
// import FloorMachineImage from "../images/orbitz-stick-machine-left-WEB-w100.png"
// import PMIconImage from "../images/PM-tim-icon-100.jpg"

const PageLayout = styled.div`
  max-width: 1400px;
  margin: 0 auto;
`

const SupportPage = () => {
  return (
    <>
      <SEO title="Support - Tech Specs, Manuals, Warranty | Timberline Cleaning Equipment" />

      <MainLayout>
        <PageHeadings title="Support" />
        <a href="/registration" className="register-btn">
          Register a Product
        </a>

        <PageLayout>
          <MachineSection
            title="Floor Machine"
            displayHood="none"
            image={FloorLargeImage}
            brochureLink="https://www.timberlineclean.com/ORBITZ-SALES-SHEET.pdf"
            techLink="https://www.timberlineclean.com/timberline-orbits-specsheet.pdf"
            partsLink="https://www.timberlineclean.com/TL-COMING-SOON.pdf"
            opsLink="https://www.timberlineclean.com/TL-COMING-SOON.pdf"
            warrantyLink="https://www.timberlineclean.com/RPS-WARRANTY-2020.pdf"
          />
          <MachineSection
            title="S Series"
            image={SImage}
            underTheHoodLink="https://www.timberlineclean.com/timberline-underthehood.pdf"
            brochureLink="https://www.timberlineclean.com/timberline-walk-behind-sm.pdf"
            techLink="https://www.timberlineclean.com/timberline-techspecs.pdf"
            partsLink="https://www.dropbox.com/s/dn19xr5z8j2ri4r/TL-S-SERIES-P-EN.pdf?dl=0"
            opsLink="https://www.dropbox.com/s/kvk65pc1qrwflpp/T-S-SERIES-OP-EN.pdf?dl=0"
            warrantyLink="https://www.timberlineclean.com/RPS-WARRANTY-2020.pdf"
          />
          <MachineSection
            title="M Series"
            image={MImage}
            underTheHoodLink="https://www.timberlineclean.com/timberline-underthehood.pdf"
            brochureLink="https://www.timberlineclean.com/timberline-walk-behind-sm.pdf"
            techLink="https://www.timberlineclean.com/timberline-techspecs.pdf"
            partsLink="https://www.dropbox.com/s/ljt4kuixorf5z6f/t-m-series-p-en.pdf?dl=0"
            opsLink="https://www.dropbox.com/s/55f8a8woffv81p8/t-m-series-op-en.pdf?dl=0"
            warrantyLink="https://www.timberlineclean.com/RPS-WARRANTY-2020.pdf"
          />
          <MachineSection
            title="L Series"
            image={LImage}
            underTheHoodLink="https://www.timberlineclean.com/timberline-underthehood.pdf"
            brochureLink="https://www.timberlineclean.com/timberline-walk-behind-sm.pdf"
            techLink="https://www.timberlineclean.com/timberline-techspecs.pdf"
            partsLink="https://www.dropbox.com/s/d4hfjc714ofn6x6/t-l-series-p-en.pdf?dl=0"
            opsLink="https://www.dropbox.com/s/fesp0mxj3v2ki7u/t-l-series-op-en.pdf?dl=0"
            warrantyLink="https://www.timberlineclean.com/RPS-WARRANTY-2020.pdf"
          />
          <MachineSection
            title="XL Series"
            image={XlImage}
            underTheHoodLink="https://www.timberlineclean.com/timberline-underthehood.pdf"
            brochureLink="https://www.timberlineclean.com/timberline-walk-behind-sm.pdf"
            techLink="https://www.timberlineclean.com/timberline-techspecs.pdf"
            partsLink="https://www.dropbox.com/s/6ubm2v07fsauqqt/t-xl-series-p-en.pdf?dl=0"
            opsLink="https://www.dropbox.com/s/ltqd19o13g20046/t-xl-series-op-en.pdf?dl=0"
            warrantyLink="https://www.timberlineclean.com/RPS-WARRANTY-2020.pdf"
          />
          <h3 style={{ borderBottom: "2px solid #5595F7" }}>
            Discontinued Models
          </h3>
          <MachineSection
            title="XS Compact"
            displayHood="none"
            image={XslargeImage}
            brochureLink="https://www.timberlineclean.com/TL-XSS-XSC-BROCHURE-V20073.pdf"
            techLink="https://www.timberlineclean.com/XS-Timberline-Specs.pdf"
            partsLink="https://www.timberlineclean.com/T-XS-S-P-EN.pdf"
            opsLink="https://www.timberlineclean.com/TL-XS-STANDARD-OP-EN.pdf"
            warrantyLink="https://www.timberlineclean.com/RPS-WARRANTY-2020.pdf"
          />
          <MachineSection
            title="XS Standard"
            displayHood="none"
            image={SXStandardImage}
            brochureLink="https://www.timberlineclean.com/TL-XSS-XSC-BROCHURE-V20073.pdf"
            techLink="https://www.timberlineclean.com/XS-Timberline-Specs.pdf"
            partsLink="https://www.timberlineclean.com/T-XS-S-P-EN.pdf"
            opsLink="https://www.timberlineclean.com/TL-XS-STANDARD-OP-EN.pdf"
            warrantyLink="https://www.timberlineclean.com/RPS-WARRANTY-2020.pdf"
          />
        </PageLayout>
      </MainLayout>
    </>
  )
}

export default SupportPage
